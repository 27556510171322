import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import api from 'api/axiosConfig'
import { TCategory } from './type'

const initialState: TCategory = {
  data: [],
  loading: false,
  error: null,
}

export const fetchCategory = createAsyncThunk('category/fetchCategoryData', async (category_id: any = process.env.REACT_APP_CATEGORY_ID ) => {
  try {
    const response = await api.get(`/v1/categories/${category_id}/children`)

    return response.data
  } catch (err) {
    throw Error('Failed to get category data')
  }
})

export const fetchCategoryTreeData = createAsyncThunk('category/fetchCategoryTreeData', async () => {
  try {
    const response = await api.get(`/v1/categories/tree?parent_id=${process.env.REACT_APP_CATEGORY_ID}`)

    return response.data
  } catch (err) {
    throw Error('Failed to get category tree data')
  }
})

const categorySlice = createSlice({
  name: 'category',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchCategory.pending, state => {
        state.loading = true
        state.error = null
      })
      .addCase(fetchCategory.fulfilled, (state, action) => {
        state.loading = false
        state.data = action.payload
      })
      .addCase(fetchCategory.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message || 'failed to fetch category'
      })
  },
})

const categoryTreeSlice = createSlice({
  name: 'category tree',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchCategoryTreeData.pending, state => {
        state.loading = true
        state.error = null
      })
      .addCase(fetchCategoryTreeData.fulfilled, (state, action) => {
        state.loading = false
        state.data = action.payload
      })
      .addCase(fetchCategoryTreeData.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message || 'failed to fetch category tree'
      })
  },
})

export { categorySlice, categoryTreeSlice }
