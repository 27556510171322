import type { FC } from 'react'

import { CheckedIcon } from 'assets'
import { Button, RatingStars } from 'components'

import styles from './ProductItem.module.scss'
import { Link } from 'react-router-dom'

const ProductItem: FC<any> = ({ data }) => {
  return (

    // <div className={styles.wrapper}>
    //   <div className={styles.wrapper__image}>
    //     {data.images && data.images.length > 0 ? (
    //       <img width='580px' height='624px' alt='soldier' src={data.images[0].thumb} />
    //     ) : (
    //       <img width='580px' height='624px' alt='default' src='	https://staging-1.buymeua.shop/images/no_image.png' />
    //     )}
    //   </div>

    //   <div className={styles.wrapper__description}>
    //     <h3 className={styles.wrapper__description__title}>{data.name}</h3>

    //     <RatingStars rate={4} isRed className={styles.wrapper__description__stars} />

    //     <h4 className={styles.wrapper__description__price}>{data.price} ₴ / дроп ціна</h4>

    //     <p className={styles.wrapper__description__text}>
    //       Костюм складається з сорочки Ubacs та штанів. До комплекту також входять запатентовані знімні наколінники
    //       та налокітники AirFlex Combat
    //     </p>

    //     <div className={styles.wrapper__description__about}>
    //       <div>
    //         <div className={styles.wrapper__description__about__item}>
    //           <CheckedIcon />

    //           <p className={styles.wrapper__description__about__item__text}>Бавовняна основа</p>
    //         </div>

    //         <div className={styles.wrapper__description__about__item}>
    //           <CheckedIcon />

    //           <p className={styles.wrapper__description__about__item__text}>Водостійка тканина</p>
    //         </div>
    //       </div>

    //       <div>
    //         <div className={styles.wrapper__description__about__item}>
    //           <CheckedIcon />

    //           <p className={styles.wrapper__description__about__item__text}>Продуманий крій</p>
    //         </div>

    //         <div className={styles.wrapper__description__about__item}>
    //           <CheckedIcon />

    //           <p className={styles.wrapper__description__about__item__text}>Перевірена якість</p>
    //         </div>
    //       </div>
    //     </div>

    //     <Link to={`/product/${data.product_id}`}>
    //       {' '}
    //       <Button className={styles.wrapper__description__button}>Купити</Button>{' '}
    //     </Link>
    //   </div>
    // </div>
    null
  )
}

export default ProductItem
