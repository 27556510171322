import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import api from 'api/axiosConfig'

const initialState: any = {
  data: [],
  blog: [],
  loading: false,
  error: null,
  sizes: [],
}


export const fetchStoreArticles = createAsyncThunk('product/fetchStoreArticles', async () => {
  try {
    const response = await api.get(`/v1/store/${process.env.REACT_APP_STORE_ID}/informations`)

    return response.data
  } catch (err) {
    throw Error('Failed to get new products data')
  }
})


export const fetchStoreArticle = createAsyncThunk('product/fetchStoreArticle', async (id:number) => {
  try {
    const response = await api.get(`/v1/store/${process.env.REACT_APP_STORE_ID}/informations/${id}`)

    return response.data
  } catch (err) {
    throw Error('Failed to get new products data')
  }
})

const policySlice = createSlice({
  name: 'policy',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchStoreArticles.pending, state => {
        state.loading = true
        state.error = null
      })
      .addCase(fetchStoreArticles.fulfilled, (state, action) => {
        state.loading = false
        state.data = action.payload
      })
      .addCase(fetchStoreArticles.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message || 'failed to fetch policy'
      })
      .addCase(fetchStoreArticle.pending, state => {
        state.loading = true
        state.error = null
      })
      .addCase(fetchStoreArticle.fulfilled, (state, action) => {
        state.loading = false
        state.blog = action.payload
      })
      .addCase(fetchStoreArticle.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message || 'failed to fetch policy'
      })
  },
})

export { policySlice }
