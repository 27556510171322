import type { FC, KeyboardEvent } from 'react'

import { SearchIcon } from 'assets'

import styles from './Search.module.scss'
import { useAppDispatch, useAppSelector } from 'hooks/useTypedSelector'
import { fetchSearchResults, setCategoryId, setQuery } from 'store/features/Search/searchSlice'
import { useLocation, useNavigate } from 'react-router'

const Search: FC = () => {
  const dispatch = useAppDispatch()

  const navigate = useNavigate()
  const location = useLocation(); 



  const query = useAppSelector(state => state.search.query)

  const handleSearch = () => {
    if (query) {
      dispatch(setCategoryId(process.env.REACT_APP_CATEGORY_ID))

      dispatch(fetchSearchResults(query))
      navigate(`/app/categories?search=${query}`), setQuery('')
    }
  }

  const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSearch()
    }
  }

  return (
    <div style={{padding: location.pathname === '/' ? 10 : 0}} className={styles.wrapper}>
      <input
        value={query}
        onChange={e => dispatch(setQuery(e.target.value))}
        onKeyPress={handleKeyPress}
        placeholder='Введіть назву товару або артикул'
        type='text'
        className={styles.wrapper__input}
      />

      <button onClick={handleSearch} className={styles.wrapper__button}>
        <SearchIcon />
      </button>
    </div>
  )
}

export default Search
